.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  background-color: white;
  min-height: 92.3vh;
  min-width: 80vw;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  align-items: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
  padding-top: 100px;
  padding-left: 100px;
}

.App-navbar {
  display: flex;
}

.App-link {
  /* color: #00b31b; */
  /* color: #3a86ff; */
  color: #09009f;
  /* background: -webkit-linear-gradient(45deg, #09009f, #00ff95 98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  /* padding-right: 20px; */
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  text-decoration: none;
}

.App-link:hover {
  color: white !important;
  background-color: #09009f;

  animation: fadeIn 500ms;
  -webkit-animation: fadeIn 250ms;
  -moz-animation: fadeIn 250ms;
  -o-animation: fadeIn 250ms;
  -ms-animation: fadeIn 250ms;
}

.App-softwareEngineer {
  padding-bottom: 30px;
  animation: fadeIn 4s;
  -webkit-animation: fadeIn 4s;
  -moz-animation: fadeIn 4s;
  -o-animation: fadeIn 4s;
  -ms-animation: fadeIn 4s;
}

.App-hTag {
  margin: 0;
}

.App-hTag-noBold {
  font-weight: normal !important;
}

.App-name {
  background: -webkit-linear-gradient(45deg, #09009f, #00ff95 95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-right: 20px;
}

.App-Timeline-Item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;
  text-align: start !important;
}

.App-Timeline-Item-TitleCompany {
  display: flex;
  align-items: center;
  padding-top: 70px;
}

.App-Timeline-Item-TitleCompany-Details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 35px;
}

.App-About {
  width: 700px;
  text-align: left;
}

.App-About-Items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-top: 70px; */
}
.App-Project-Item {
  display: flex;
  align-items: center;
  padding-top: 30px;
}

.blogPost {
  max-width: 1400px;
  padding-right: 100px;
  text-align: start;
}

.blogPost h6 {
  font-weight: normal !important;
}

@media screen and (max-width: 850px) {
  .App-header {
    min-height: 100vh;
    padding-top: 0px;
    padding-left: 0px;
  }
  .App-About {
    width: 100%;
  }
  .blogPost {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ProfilePic {
  border-radius: 50%;
}
